import { FloatingWhatsApp } from 'react-floating-whatsapp'

function App() {
  return (
      <FloatingWhatsApp 
        phoneNumber="628119079555"
        accountName="Purwantara Customer Service"
        statusMessage="Online"
        allowEsc="true"
        notification="true"
        notificationSound="true"
        chatMessage='Ada yang bisa kami bantu? &#13;&#10;How can we help you?'
        avatar="https://ppn-primary.oss-ap-southeast-5.aliyuncs.com/kmtIYJFzanaYcIRtnCLH0H8pj9qNCALasYkq02Rg.jpg"
        chatboxHeight={350}
        chatboxStyle={{ width: 400 }}
      />
  );
}

export default App;
